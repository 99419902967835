<template>
  <footer>
    <div class="info">
      <div class="top-footer">
        <div class="logo">
          <img src="@/assets/images/logo-full.svg" alt="" />
        </div>
        <div class="information">
          <h4>Firmensitz - Rechnungsanschrift</h4>

          <p>
            Wild Electro s.r.o. <br />
            ČSA 1861/3A, Dolný Kubín 026 01
          </p>
          <p>
            Id. Nr.: 55 504 701, <br />
            Steuernummer: 2122030306
          </p>
          <p>UID Nr.: SK2122030306</p>
        </div>
        <div class="important-links">
          <h4>Wichtige Verbindungen</h4>

          <router-link to="/GDPR">IMPRESSUM</router-link>
          <router-link to="/GDPR">DATENSCHUTZERKLÄRUNG</router-link>
          <router-link to="/login">Login</router-link>
        </div>
      </div>
      <div class="bottom-footer">
        <p class="copyright">© 2022 Wild Electro s.r.o.</p>
        <div class="links">
          <a
            target="_blank"
            href="https://www.facebook.com/people/HG-Solution/100067480704979/?paipv=0&eav=Afa_v9-sMLSYyqqs69D9Le8nR0qZQsxU0xv21ZIAg6yQPap7JZ9GItZ1JQUX5GGERWQ&_rdr"
            ><img src="@/assets/images/facebookIcon.svg" alt=""
          /></a>
          <!-- <a href="#" target="_blank"><img src="@/assets/images/instagramIcon.svg" alt="" /></a> -->
          <a href="mailto:info@hg-solution.sk"
            ><img src="@/assets/images/mailIcon.svg" alt=""
          /></a>
          <a
            target="_blank"
            href="https://www.google.com/maps?q=Radlinského+1735/29,+Dolný+Kubín+026+01"
          >
            <img src="@/assets/images/mapIcon.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="navigation">
      <h4>Website-Navigation</h4>

      <ul>
        <li><router-link to="/">Einführung</router-link></li>
        <li><router-link to="/about-us">Wir über uns</router-link></li>
        <li><router-link to="/services">Unsere Leistungen</router-link></li>
        <li><router-link to="/references">Referenzen</router-link></li>
        <li><router-link to="/how-works">Wie arbeiten wir</router-link></li>
        <li><router-link to="/contact">Kontakt</router-link></li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// IMPORT COLORS
@import "@/assets/sass/_colors.scss";

footer {
  background: $grey-color;
  color: $text-invert-color;
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.05em;

  h4 {
    color: rgba(87, 87, 87, 1);
    font-size: 1em;
  }

  .info {
    width: 80%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navigation {
    width: 15%;

    text-align: right;
    padding-right: 5%;

    ul {
      padding-left: 0;
      list-style-type: none;

      li {
        margin-bottom: 0.4em;
        text-align: left;
        a {
          color: $text-invert-color;
          text-decoration: none;
        }
      }
    }

    h4 {
      text-align: left;
    }
  }
}

.information p {
  margin: 0.5em 0;
}

.top-footer {
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;

    img {
      width: 10em;
    }
  }

  .important-links {
    display: flex;
    flex-direction: column;

    a {
      color: $text-invert-color;
      text-decoration: none;
      margin-bottom: 0.5em;
    }
  }
}

.bottom-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1em 5%;

  .links {
    padding-right: 27.5%;
    display: flex;
    align-items: center;

    a img {
      height: 1.4em;
      padding: 0.5em;
    }

    a:nth-child(2) img {
      height: 1.2em;
    }
  }
}

.router-link-active {
  font-weight: 700;
}

@media screen and (max-width: 1080px) {
  .navigation {
    display: none;
  }

  footer .info {
    width: 100%;
    font-size: 90%;
  }
  .bottom-footer .links {
    padding-right: 0;
  }
}

@media screen and (max-width: 750px) {
  .top-footer,
  .bottom-footer {
    flex-direction: column;
    text-align: center;

    .links {
      justify-content: center;
    }
  }
}
</style>
